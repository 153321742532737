export const personas = [
  "Albert Einstein",
  "George Washington",
  "Third Grade Student",
  "Subject Matter Expert",
  "Socrates",
  "Isaac Newton",
  "Gandalf",
  "Harry Potter",
  "Elementary School Teacher",
  "Software Engineer",
  "Confucius",
  "Homer Simpson",
  "First Time Home Buyer",
  "College Graduate",
  "Marilyn Monroe",
  "Benjamin Franklin",
  "Aristotle",
  "Shakespeare",
  "Abraham Lincoln",
  "Nelson Mandela",
  "Sherlock Holmes",
  "Marie Curie",
  "Martin Luther King Jr.",
  "Captain America",
  "Yoda",
  "Thomas Edison",
  "The Hulk",
  "Professional Athlete",
  "New Parent",
  "Winston Churchill",
  "Frankenstein",
  "Leonardo Da Vinci",
  "Galileo Galilei",
  "Spiderman",
  "Retiree",
  "Healthcare Professional",
  "Buddha",
  "Freud",
  "Joan of Arc",
  "Pablo Picasso",
  "Indiana Jones",
  "High School Student",
  "Veteran",
  "Beethoven",
  "Superman",
  "The Pope",
  "Charlie Chaplin",
  "Gandhi",
  "Entrepreneur",
  "Michelangelo",
  "Queen Elizabeth II",
  "Star Wars Fan",
  "Julius Caesar",
  "Elon Musk",
  "Daenerys Targaryen",
  "Iron Man",
  "Stay-at-Home Parent",
  "Barack Obama",
  "Napoleon Bonaparte",
  "Steve Jobs",
  "Oscar Wilde",
  "Tolkien Fan",
  "Small Business Owner",
  "Vladimir Putin",
  "Anne Frank",
  "Personal Trainer",
  "Agatha Christie",
  "Batman",
  "Charles Darwin",
  "Dumbledore",
  "Professional Chef",
  "Charles Dickens",
  "Mother Teresa",
  "Harriet Tubman",
  "High School Teacher",
  "Confident Public Speaker",
  "Helen Keller",
  "Frida Kahlo",
  "Fitness Enthusiast",
  "Susan B. Anthony",
  "Mark Twain",
  "Captain Picard",
  "Graphic Designer",
  "Robin Hood",
  "Leonardo DiCaprio",
  "Sun Tzu",
  "Bilbo Baggins",
  "Oprah Winfrey",
  "Neil Armstrong",
  "Jane Austen",
  "Undergraduate Student",
  "William the Conqueror",
  "Rosa Parks",
  "Paul McCartney",
  "Chef Gordon Ramsay",
  "Captain Kirk",
  "Neil deGrasse Tyson",
  "Plato",
  "John Lennon",
  "Charles Lindbergh",
  "Vincent Van Gogh",
  "Indiana Jones",
  "Thor",
  "Mahatma Gandhi",
  "David Bowie",
  "Charles Babbage",
  "Karl Marx",
  "Confused Tourist",
  "Ernest Hemingway",
  "Tinfoil Hat Wearer Convinced Aliens Control the Government",
  "Flat Earth Society's Most Passionate Speaker",
  "Retired Pirate Looking for Landlubber Job",
  "Guinness World Record Holder for Most Spoons Balanced on Face",
  "Frustrated Inventor of Perpetual Motion Machines",
  "Dedicated Sandwich Artist Pursuing a Master's in Sandwich Making",
  "Former Child Prodigy Turned Professional Kazoo Player",
  "Ex-Astronaut Who Claims to Have Befriended Martians",
  "Serial Hobbyist Who Picks Up a New Hobby Every Week",
  "Professional Queue Stand-in Who’s Always Waiting in Line for Something",
  "Over-Enthusiastic Life Coach for Inanimate Objects",
  "Over-Caffeinated Coffee Shop Philosopher",
  "Pirate Captain in Search of Lost Treasure",
  "Robot Trying to Understand Human Emotions",
  "Medieval Knight Time-Traveled to the Present",
  "Talking Dog That Gives Life Advice",
  "Enthusiastic Alien Tourist on Earth",
  "Unicorn Enthusiast Who Can’t Stop Talking About Unicorns",
  "Old West Cowboy in Modern City",
  "Gentleman Detective with an Overly Dramatic Flair",
  "Motivational Speaker for Superheroes",
];

export const topics_controversal = [
  "The Ethics of Euthanasia and Assisted Suicide",
  "Reproductive Rights and Abortion Laws",
  "Genetic Engineering of Humans: Playing God?",
  "Should Children Be Allowed to Transition Genders?",
  "Racial Profiling by Law Enforcement",
  "The Ethics of Using Animals in Medical Research",
  "Legalization of Psychedelic Substances for Medical Use",
  "The Israeli-Palestinian Conflict",
  "Should Religious Symbols Be Allowed in Public Spaces?",
  "Debating the Validity of Gender Pay Gap",
  "The Legalization and Regulation of Sex Work",
  "Freedom of Speech vs. Hate Speech",
  "Chemical Castration for Sex Offenders",
  "The Ethics of Human Cloning",
  "The Opioid Crisis: Pharmaceutical Companies' Accountability",
  "Secession Movements Around the World",
  "The Morality of Capital Punishment",
  "Internet Censorship in Authoritarian Regimes",
  "Mass Surveillance and National Security",
  "The Ethics of Whistleblowing",
  "Transhumanism: Enhancing the Human Species",
  "The Right to Bear Arms vs. Gun Control",
  "Affirmative Action: Equality or Reverse Discrimination?",
  "Cultural Appropriation in the Modern World",
  "Normalization of Cosmetic Surgery in Society",
  "Monogamy vs. Polyamory",
  "Conspiracy Theories and Their Influence on Society",
  "Religious Freedom vs. Discrimination",
  "Child Gender Transition and Parental Rights",
  "Selective Abortion Based on Genetic Traits",
  "Should Vaccination Exemptions be Allowed for Religious Reasons?",
  "Biological Basis of Gender Identity",
  "Secularism vs. Religion in Government",
  "The Right to Offend: The Limits of Freedom of Speech",
  "Should Hate Speech be Protected?",
  "Confronting Historical Injustices and Reparations",
  "The Debate on Circumcision",
  "Private Prisons and the Prison-Industrial Complex",
  "The Debate on Pornography: Freedom of Expression or Exploitation",
  "Decriminalization of Drugs: A Harm Reduction Approach",
  "The Influence of Political Correctness on Society",
  "Civil Disobedience: When is it Justified?",
  "The Ethics of Torture in Counter-terrorism",
  "The Legitimacy of International Military Interventions",
  "Income Inequality and Wealth Redistribution",
  "The Debate on Gentrification and Urban Development",
  "Mandatory Military Service",
  "Radical Life Extension and the Ethics of Immortality",
  "The Ethics of Geoengineering to Combat Climate Change",
];

export const topics = [
  "The Ethics of Euthanasia and Assisted Suicide",
  "Reproductive Rights and Abortion Laws",
  "Genetic Engineering of Humans: Playing God?",
  "Should Children Be Allowed to Transition Genders?",
  "Racial Profiling by Law Enforcement",
  "The Ethics of Using Animals in Medical Research",
  "Legalization of Psychedelic Substances for Medical Use",
  "The Israeli-Palestinian Conflict",
  "Should Religious Symbols Be Allowed in Public Spaces?",
  "Debating the Validity of Gender Pay Gap",
  "The Legalization and Regulation of Sex Work",
  "Freedom of Speech vs. Hate Speech",
  "Chemical Castration for Sex Offenders",
  "The Ethics of Human Cloning",
  "The Opioid Crisis: Pharmaceutical Companies' Accountability",
  "Secession Movements Around the World",
  "The Morality of Capital Punishment",
  "Internet Censorship in Authoritarian Regimes",
  "Mass Surveillance and National Security",
  "The Ethics of Whistleblowing",
  "Transhumanism: Enhancing the Human Species",
  "The Right to Bear Arms vs. Gun Control",
  "Affirmative Action: Equality or Reverse Discrimination?",
  "Cultural Appropriation in the Modern World",
  "Normalization of Cosmetic Surgery in Society",
  "Monogamy vs. Polyamory",
  "Conspiracy Theories and Their Influence on Society",
  "Religious Freedom vs. Discrimination",
  "Child Gender Transition and Parental Rights",
  "Selective Abortion Based on Genetic Traits",
  "Should Vaccination Exemptions be Allowed for Religious Reasons?",
  "Biological Basis of Gender Identity",
  "Secularism vs. Religion in Government",
  "The Right to Offend: The Limits of Freedom of Speech",
  "Should Hate Speech be Protected?",
  "Confronting Historical Injustices and Reparations",
  "The Debate on Circumcision",
  "Private Prisons and the Prison-Industrial Complex",
  "The Debate on Pornography: Freedom of Expression or Exploitation",
  "Decriminalization of Drugs: A Harm Reduction Approach",
  "The Influence of Political Correctness on Society",
  "Civil Disobedience: When is it Justified?",
  "The Ethics of Torture in Counter-terrorism",
  "The Legitimacy of International Military Interventions",
  "Income Inequality and Wealth Redistribution",
  "The Debate on Gentrification and Urban Development",
  "Mandatory Military Service",
  "Radical Life Extension and the Ethics of Immortality",
  "The Ethics of Geoengineering to Combat Climate Change",
  "Artificial Intelligence: Boon or Bane",
  "Climate Change",
  "Gun Control Laws",
  "Veganism Vs. Meat Eating",
  "Capitalism Vs. Socialism",
  "Online Learning Vs. Traditional Learning",
  "Should Voting be Mandatory",
  "Gender Wage Gap",
  "Universal Basic Income",
  "Colonization of Mars",
  "Role of Technology in Education",
  "The Future of Renewable Energy",
  "Cryptocurrency: Future of Finance",
  "Use of Drones in Warfare",
  "Role of UN in Peacekeeping",
  "Privacy in the Age of Internet",
  "Animal Testing: Ethical or Unethical",
  "Is Space Exploration Worth the Cost",
  "Should Professional Athletes Be Role Models",
  "Effectiveness of Standardized Testing",
  "The Influence of Media on Society",
  "Human Genetic Engineering",
  "NuclearEnergy: Safe or Not",
  "Impact of Video Games on Youth",
  "Censorship of Art and Culture",
  "The Right to Euthanasia",
  "Legalization of Marijuana",
  "Impact of Social Media on Mental Health",
  "The Role of Zoos in Conservation",
  "Merit of the Electoral College",
  "Violence in Movies and its Effect on Society",
  "Immigration Policy",
  "Abolition of Death Penalty",
  "Child Labor Laws",
  "Feminism and its Impact on Society",
  "Universal Healthcare",
  "The Role of Religion in Society",
  "Should Corporal Punishment be Allowed in Schools",
  "Should Animals be Used for Entertainment",
  "The Future of Artificial Intelligence",
  "The Ethics of Cloning",
  "The Pros and Cons of Homeschooling",
  "Is the Current Education System Adequate",
  "Impact of Technology on Jobs",
  "Should GMOs be Regulated",
  "Should Vaccination be Compulsory",
  "Digital Privacy",
  "The Future of Books: Print Vs. Digital",
  "The Morality of War",
  "Role of NATO in Today's World",
  "The Future of Work: Remote Vs. In-Office",
  "Is Globalization Good or Bad",
  "Should Recycling be Mandatory",
  "Role of Parents Vs. Teachers in Education",
  "Should Freedom of Speech be Absolute",
  "The Impact of Celebrity Culture on Society",
  "The Ethics of Advertising to Children",
  "Should Internet Access be a Basic Human Right",
  "The Implications of a Cashless Society",
  "The Ethics of Surrogacy",
  "Does Multiculturalism Strengthen or Weaken Society",
  "The Role of Art in Society",
  "Should College Education be Free",
  "Is a Four Day Work Week Beneficial",
  "The Future of AI in Healthcare",
  "Are Exams an Effective Way to Measure Intelligence",
  "Should Physical Education be Mandatory in Schools",
  "The Role of Video Games in Education",
  "Should Prisoners have the Right to Vote",
  "Are Smartphones Making Us Dumber",
  "Should Animal Products be Banned",
  "The Influence of Advertising on Consumer Behavior",
  "Should Taxation on Wealthy Individuals be Increased",
  "The Ethics of Organ Trade",
  "Should Sports Betting be Legalized",
  "Is Homeschooling More Effective Than Traditional Schooling",
  "The Role of Music in Education",
  "The Effectiveness of the UN",
  "Are Social Networking Sites Good for Our Society",
  "Should Juveniles be Tried as Adults",
  "The Pros and Cons of Electric Vehicles",
  "The Importance of Space Exploration",
  "Should the Drinking Age be Lowered",
  "Are Beauty Pageants Exploitative",
  "Should Prostitution be Legalized",
  "The Impact of Fast Food on Health",
  "The Importance of Arts Education",
  "The Role of Trade Unions",
  "Should the School Day Start Later",
  "The Ethics of Hunting",
  "Are Renewable Energies Sustainable",
  "The Importance of Teaching Financial Literacy in Schools",
  "Should Same-Sex Marriage be Legalized Worldwide",
  "The Future of Cryptocurrencies",
  "The Importance of Teaching Coding in Schools",
  "The Role of Technology in the Classroom",
  "Are Zoos Necessary for Education",
  "Is Nuclear Power the Answer to Climate Change",
  "Should GMO Foods be Labeled",
  "The Role of Sports in Society",
  "Should Performance Enhancing Drugs be Allowed in Sports",
  "The Impact of Reality TV on Society",
  "Should the Voting Age be Lowered",
];
